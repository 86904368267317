const PopUp = () => {
  const closePopup = document.querySelector(".closePopUp");
  const popupContainer = document.querySelector(".pop-up-container");

  if (closePopup && popupContainer) {
    closePopup.addEventListener("click", (e) => {
      // console.log("it is working");
      popupContainer.classList.add("closed");
    });
  }
};

export { PopUp };
